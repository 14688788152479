<template>
  <article>
    <div v-if="computing"><span class="loader"></span></div>
    <div v-if='!computing'>
      <table class="table is-fullwidth is-condensed">
        <tbody>
          <tr v-for="match in matches" :key="match.key">
            <th><span class="tooltip is-tooltip-right" :data-tooltip="match.branches.join(', ')">{{match.key}}</span></th>
            <!-- <td>{{match.branches.length}}</td> -->
            <td>
              <span class="tag" :class="{'is-warning': match.found.length === 0, 'is-success': match.found.length > 0}">
                {{match.found.length}}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </article>
</template>

<script>
import {mapGetters} from 'vuex';
export default {
    name: 'ProspectionTargets',
    computed: {
      ...mapGetters('prospection', ['computing', 'matches'])
    }
}
</script>
