<template>
  <main>
    <section class="section">
      <div>
      <nav class="breadcrumb is-medium" aria-label="breadcrumbs">
        <ul>
          <sqr-crumb name="broker" :bid="bid">{{$t('breadcrumb_broker', broker)}}</sqr-crumb>
          <sqr-crumb name="relations" :bid="bid" label="breadcrumb_relations"/>
          <sqr-crumb name="relation" :bid="bid" :rid="rid">{{$t('breadcrumb_relation', relation)}}</sqr-crumb>
          <sqr-crumb name="relation-prospection" :bid="bid" :rid="rid">{{$t('breadcrumb_relation_prospection')}}</sqr-crumb>
        </ul>
      </nav>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <h2 class="title is-5">
          {{ $t('relation_prospect_actions_title') }}
        </h2>
        <span class="field is-grouped is-grouped-multiline" v-if="relation">
          <div class="control" v-for="tag in relation.tags" :key="tag">
            <div class="tags has-addons">
              <span class="tag">{{ tag }}</span>
              <a class="tag is-delete" @click="tagRemove(tag)"></a>
            </div>
          </div>
          <div class="control">
            <sqr-input-text
              placeholder="relation_tags_new"
              @keyup.enter="tagAdd($event.target.value)"
              size="small"
            />
          </div>
        </span>
        <div class="columns">
          <div class="column is-4">
            <prospection-targets />
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import ProspectionTargets from '@/components/ProspectionTargets';
import SqrInputText from '@/sqrd/SqrInputText';

export default {
  name: 'RelationProspection',
  components: {
    ProspectionTargets,
    SqrInputText
  },
  props: {
    bid: String,
    rid: String
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('relation', {
      relationLoading: 'loading',
      relation: 'record'
    }),
    ...mapGetters('policies', {
      policiesLoading: 'loading',
      policies: 'records'
    })
  },
  mounted() {
    this.reload();
  },
  methods: {
    ...mapActions('policies', ['load']),
    ...mapActions('relation', ['sub']),
    ...mapActions('prospection', ['compute']),
    async reload() {
      const relation = this.sub({ path: ['brokers', this.bid], id: this.rid });
      const policies = this.load({
        path: ['brokers', this.bid],
        where: [['relationId', '==', this.rid]]
      });
      const res = await Promise.all([relation, policies]);
      this.compute({ tags: res[0].tags, policies: res[1] });
    }
  }
};
</script>
